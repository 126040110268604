import { Component, HostBinding, Input } from '@angular/core';
import { keysOf } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable } from 'rxjs';
import { MenuItem } from '../models';
import { MainNavService } from '../services/main-nav.service';

interface ComponentState {
  menuItems: MenuItem[];
  activeItem: MenuItem | null;
  showXLogo: boolean;
}

// interface Projections {}

// type PublicState = Pick<ComponentState, 'xx' | 'yy'>;
type PublicState = ComponentState;

type ViewModel = PublicState; // & Projections;

const initialPublicState: PublicState = {
  menuItems: [],
  activeItem: null,
  showXLogo: false
};

const initialState: ComponentState = {
  ...initialPublicState
};

@Component({
  selector: 'mri-shared-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
  providers: [RxState]
})
export class MainNavBarComponent {
  @HostBinding('class.mri-menu-bar') hostClass = true;

  @Input() set showXLogo(showXLogo: boolean) {
    this.state.set({ showXLogo });
  }

  @Input() set menuItems(menuItems: MenuItem[]) {
    this.mainNavService.menuItems = menuItems;
  }

  vm$: Observable<ViewModel>;

  constructor(private mainNavService: MainNavService, private state: RxState<ComponentState>) {
    // Set initial state
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    this.state.connect('menuItems', this.mainNavService.menuItems$);
    this.state.connect('activeItem', this.mainNavService.activeItem$);

    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...
    this.vm$ = this.state.select(selectSlice(keysOf(initialPublicState)));

    // side effects if any...
    // none
  }

  itemHref(menuItem: MenuItem) {
    return `assets/@mri/svg-icons/mri-icon-sprite.svg#${menuItem.iconName}`;
  }

  clickIcon(activeItem: MenuItem) {
    this.mainNavService.activate(activeItem);
  }
}
