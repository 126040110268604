import { Component, Input } from '@angular/core';
import { environment } from '@mri-platform/shared/core';
import { MenuService } from '../../ui-services';

@Component({
  selector: 'mri-shared-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  renderAgBar = !environment.integrationTest;
  @Input() showXLogo = true;
  constructor(public menu: MenuService) {}
}
