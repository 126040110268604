import { Injectable } from '@angular/core';
import { AppRoutes } from '@mri-platform/import-export/core';
import { MainMenuItemService } from '@mri-platform/shared/shell';
import { DrawerMenuItem, LinkMenuItem } from '@mri-platform/shared/ui-main-nav-bar';

@Injectable()
export class AppMainMenuItemService implements MainMenuItemService {
  private home: LinkMenuItem = {
    iconName: 'home',
    title: 'Home',
    ...AppRoutes.RecentImportExport.toMenuInfo()
  };

  private jobs: LinkMenuItem = {
    iconName: 'arrows-left-right',
    title: 'Jobs',
    ...AppRoutes.ImportExportJobs.toMenuInfo()
  };

  private mappings: LinkMenuItem = {
    iconName: 'hierarchy-right',
    title: 'Mapping manager',
    ...AppRoutes.MappingManager.toMenuInfo()
  };

  private help: LinkMenuItem = {
    iconName: 'question-mark',
    title: 'User guide',
    url: 'assets/MRI_Import_Export_User_Guide_V2.00.pdf',
    opener: (url: string) => window.open(url, '_blank')
  };

  private mriSettings: DrawerMenuItem = {
    iconName: 'gear',
    title: 'Administration',
    links: [
      { text: 'Applications and data sources', ...AppRoutes.PlatformManagement.toMenuInfo() },
      { text: 'Connection management', ...AppRoutes.MappingConnections.toMenuInfo() },
      { text: 'User security settings', ...AppRoutes.UserSecuritySettings.toMenuInfo() },
      { text: 'Client management', ...AppRoutes.ClientManagement.toMenuInfo() }
    ]
  };

  get items() {
    return [this.home, this.jobs, this.mappings, this.help, this.mriSettings];
  }
}
