import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreUiModule } from '@mri-platform/shared/core-ui';
import { MainNavBarComponent } from './main-nav-bar/main-nav-bar.component';
import { NavDrawerComponent } from './nav-drawer/nav-drawer.component';

const PUBLIC_DECLARATIONS = [MainNavBarComponent, NavDrawerComponent];

@NgModule({
  imports: [CommonModule, CoreUiModule, RouterModule],
  declarations: PUBLIC_DECLARATIONS,
  exports: PUBLIC_DECLARATIONS
})
export class UiMainNavBarModule {}
