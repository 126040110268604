import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { isDrawer, isLink, isModal, MenuItem } from '../models';

@Injectable({ providedIn: 'root' })
export class MainNavService {
  private menuItemsSubject = new BehaviorSubject<MenuItem[]>([]);
  set menuItems(items: MenuItem[]) {
    this.menuItemsSubject.next(items);
  }
  get menuItems$() {
    return this.menuItemsSubject.asObservable();
  }

  private activeItemSubject = new BehaviorSubject<MenuItem | null>(null);

  activate(item: MenuItem | null) {
    let activeItem: MenuItem | null = item;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let sideEffect: () => void = () => {};
    if (item) {
      if (isLink(item)) {
        if (item.opener) {
          sideEffect = () => item.opener?.(item.url);
        }
        else {
          let newUrl = item.url;
          const menuToggle = this.containsQueryParam(this.router.url) ? '&menuToggle=true' : '?menuToggle=true';
          if (item.supportsToggle) {
            if (this.router.url.includes(item.url)) {
              newUrl = this.router.url.includes(menuToggle)
                ? this.router.url.replace(menuToggle, '')
                : this.router.url.concat(menuToggle);
            }
          }
          /*
           * There is nothing to do while routing to the new page, so no reason to mark the item active
           * Also, clears out the existing active item if clicked while a drawer is open.
           */
          activeItem = null;
          sideEffect = () => this.router.navigateByUrl(newUrl);
        }
      } else if (isDrawer(item)) {
        // if drawer menu item was clicked again, it should deactivate the menu item and close the drawer
        activeItem = this.activeItem === item ? null : item;
      } else if (isModal(item)) {
        sideEffect = () => item.open();
      }
    }

    this.activeItemSubject.next(activeItem);
    sideEffect();
  }

  private get activeItem() {
    return this.activeItemSubject.getValue();
  }

  get activeItem$() {
    return this.activeItemSubject.asObservable();
  }

  private containsQueryParam(url: string) {
    return url.includes('?') && !url.includes('?menuToggle=true') ? true : false;
  }

  constructor(private router: Router) {}
}
