import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DrawerContents, DrawerItem, drawerContentsFromMenuItem, isDrawer, isSection, nullDrawerItem } from '../models';
import { MainNavService } from '../services/main-nav.service';

interface ViewModel {
  activeItem: DrawerContents;
  isOpen: boolean;
}

@Component({
  selector: 'mri-shared-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .menu-header {
        padding: 16px 16px 0;
        color: black;
      }
    `
  ]
})
export class NavDrawerComponent {
  vm$: Observable<ViewModel>;

  constructor(private mainNavService: MainNavService) {
    const activeMenuItem$ = this.mainNavService.activeItem$.pipe(
      map(item => (item && isDrawer(item) ? drawerContentsFromMenuItem(item) : null))
    );

    this.vm$ = activeMenuItem$.pipe(
      map(activeItem => ({
        activeItem: activeItem ?? nullDrawerItem,
        isOpen: activeItem != null
      })),
      startWith({ activeItem: nullDrawerItem, isOpen: false })
    );
  }

  close() {
    this.mainNavService.activate(null);
  }

  isSection(item: DrawerItem) {
    return isSection(item);
  }
}
