import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AppGatewayNavigationEvent } from './ag-bar-navigation-event';
import { AgBarService, BarReadyStatus } from './ag-bar.service';
import { AppGatewayConfig } from './app-gateway-config';

@Component({
  selector: 'mri-ag-bar',
  template: '',
  styles: [
    `
      :host {
        /* user might not have AG bar enabled (even after GA), therefore we shouldn't reserve space for the bar */
        /* min-height: 34px; */
        display: block;
      }
    `
  ]
})
export class AgBarComponent implements OnInit {
  private agElem!: HTMLElement;

  private initialHideAppMenu = false;

  /**
   * Should AG Bar hide the menu option to allow the user to open the application tile list?
   * @default false
   * @param value true to hide, false to show app title menu
   */
  @Input() set hideAppMenu(value: boolean) {
    this.initialHideAppMenu = value;
    this.setHideAppMenuFlagAttr(value);
  }

  private initialInterceptNavigation = this.appGatewayConfig.interceptNavigation;

  /**
   * Should AG Bar navigation be intercepted?
   * Defaults to the configuration value supplied by {@link AppGatewayConfig.interceptNavigation}
   * @param value true to intercept, false to allow AG Bar to perform navigation without asking the hosting SPA app
   */
  @Input() set interceptNavigation(value: boolean) {
    this.initialInterceptNavigation = value;
    this.setInterceptNavigationFlagAttr(value);
  }

  /**
   * Emits true when the bar is initially rendered and is visible to the user.
   *
   * On initial load, the shell of the application-gateway-app component (the blue bar with the MRI logo) will not be
   * rendered until the user is validated. Subsequently, once the bar has rendered on a particular browser, it will be
   * optimistically rendered and be visible closer to page load.
   */
  @Output() barVisibilityChange = new EventEmitter<boolean>();

  /**
   * Emits the status of the bar on its way to becoming usable by the user
   *
   * Application Gateway will not render any menus (eg the 9-box applications menu) until after it has fetched
   * configuration settings from it's web api server. As a consequence there will be a delay between the
   * application-gateway-app component (the blue bar with the MRI logo) becoming visible and when core functionality is
   * ready to use.
   *
   * Three states for readiness: "pending", "fail", "success". The status will start at "pending." If the status is
   * "fail" you'll either see a failed network request or an error in the console explaining what the problem was.
   * When "success" the bar is ready to use.
   */
  @Output() barReadyStatusChange = new EventEmitter<BarReadyStatus>();

  /**
   * Emits whenever the AG Bar wants to perform a navigation but has been configured to allow these navigations to be
   * intercepted by the hosting app.
   *
   * @example
   * ```ts
   * import { Component } from '@angular/core';
   * import { AuthService } from '@mri/angular-wfe-proxy-oidc';
   * import { AG_LOGOUT_REASON, AppGatewayNavigationEvent } from '@mri/app-gateway-bar';
   *
   *  @Component({
   *    selector: 'app-root',
   *    template: '<mri-ag-bar (barNavigation)="interceptBarNavigation($event)" ></mri-ag-bar>',
   *  })
   *  export class AppComponent {
   *
   *    constructor(private authService: AuthService) {}
   *
   *    promptUserAboutLeavingApp(): boolean {
   *      // logic here to check if the current page is dirty and if so whether the user is ok losing this work.
   *      // here we just faking that the page is dirty
   *      return confirm('You have unsaved data, are you sure you want to continue?');
   *    }
   *
   *    interceptBarNavigation(evt: AppGatewayNavigationEvent) {
   *      if (evt.detail.context.reason === AG_LOGOUT_REASON) {
   *        // logout of SPA instead of letting AG try and logout of welcome
   *        evt.detail.navigationController.cancel();
   *        this.authService.logOut();
   *      } else if (!evt.detail.context.newTab && !this.promptUserAboutLeavingApp()) {
   *        evt.detail.navigationController.cancel();
   *      } else {
   *        evt.detail.navigationController.accept();
   *      }
   *    }
   *  }
   * ```
   */
  @Output() barNavigation = new EventEmitter<AppGatewayNavigationEvent>();

  constructor(
    private appGatewayConfig: AppGatewayConfig,
    @Inject(DOCUMENT) private readonly doc: Document,
    private agBarService: AgBarService,
    private hostElemRef: ElementRef
  ) {}

  ngOnInit() {
    this.loadScript();
  }

  private addAG() {
    this.agElem = this.doc.createElement('application-gateway-app');

    this.agElem.addEventListener('shell-rendered-changed', (e: Event) => {
      const isBarVisible = (e as CustomEvent).detail.value;
      this.barVisibilityChange.emit(isBarVisible);
      this.agBarService.setBarVisibleStatus(isBarVisible);
    });

    this.agElem.addEventListener('ready-status-changed', (e: Event) => {
      const readyStatus = (e as CustomEvent).detail.value;
      this.barReadyStatusChange.emit(readyStatus);
      this.agBarService.setBarReadyStatus(readyStatus);
    });

    this.agElem.addEventListener('ag:navigation', (e: unknown) => {
      const navEvt = e as AppGatewayNavigationEvent;
      this.barNavigation.emit(navEvt);
      this.agBarService.publishBarNavigationEvent(navEvt);
    });

    this.agElem.setAttribute('api-host', this.appGatewayConfig.baseApiUrl);
    this.setInterceptNavigationFlagAttr(this.initialInterceptNavigation);
    this.setHideAppMenuFlagAttr(this.initialHideAppMenu);
    this.appendChildElement(this.agElem);
  }

  private appendChildElement(element: HTMLElement) {
    if (this.hostElemRef.nativeElement == null) {
      return;
    }
    this.hostElemRef.nativeElement.appendChild(element);
  }

  private loadScript() {
    const scriptLoaderElem = this.doc.createElement('script');
    scriptLoaderElem.src = `${this.appGatewayConfig.webUrl}/src/ag-bar.js`;
    scriptLoaderElem.onload = () => this.addAG();
    this.appendChildElement(scriptLoaderElem);
  }

  private setAgBarFlagAttr(name: string, enabled: boolean = true) {
    if (!this.agElem) {
      return;
    }
    if (enabled) {
      this.agElem.setAttribute(name, '');
    } else {
      this.agElem.removeAttribute(name);
    }
  }

  private setHideAppMenuFlagAttr(enabled: boolean = true) {
    this.setAgBarFlagAttr('hide-app-menu', enabled);
  }

  private setInterceptNavigationFlagAttr(enabled: boolean = true) {
    this.setAgBarFlagAttr('intercept-navigation', enabled);
  }
}
