<mri-ag-bar *ngIf="renderAgBar; else noBar"></mri-ag-bar>
<ng-template #noBar>
  <div class="no-ag-bar" *ngIf="!renderAgBar"></div>
</ng-template>
<nav>
  <mri-shared-main-nav-bar [menuItems]="menu.mainMenuItems$ | async" [showXLogo]="showXLogo"></mri-shared-main-nav-bar>
</nav>
<main>
  <mri-shared-nav-drawer></mri-shared-nav-drawer>
  <mri-shared-page-loading></mri-shared-page-loading>
  <router-outlet></router-outlet>
  <router-outlet name="drawer"></router-outlet>
</main>
<ng-content select="[pageFooter]"></ng-content>
