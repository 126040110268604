import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { noop } from 'rxjs';

interface User {
  name: string;
}

interface DropdownData {
  text: string;
  disabled: boolean;
  handleClick: () => void;
}

@Component({
  selector: 'mri-shared-page-footer',
  template: `
    <div id="footer">
      <div class="logo-section">
        <img alt="MRI" height="24" src="assets/images/logo-blue-no-tagline.svg" />
        <span id="MenuAppName">{{ appName }}</span>
      </div>
      <kendo-dropdownbutton
        role="menu"
        fillMode="flat"
        class="shell-footer-menu"
        [data]="data"
        [popupSettings]="{ align: 'right', appendTo: 'component' }"
        [arrowIcon]="true"
      >
        {{ user?.name }}
        <ng-template kendoDropDownButtonItemTemplate let-dataItem>
          <div class="item-option mri-pt-small mri-pb-small" (click)="dataItem.handleClick()">
            {{ dataItem.text }}
          </div>
        </ng-template>
      </kendo-dropdownbutton>
    </div>
  `,
  styles: [
    `
      #footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .logo-section {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .k-dropdown-button.k-widget {
        /* background white is still visible even after flat look property is added on kendo dropdown
        temporarily made it transparent. todo: will be replaced later */
        background: transparent;
      }
      .item-option {
        width: 100%;
        /* below css gets applied only when max-width is defined on kendo-popup*/
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageFooterComponent implements OnChanges {
  @Input() user: User = { name: '' };
  @Input() appName = '';
  @Input() isEnableSwitchClient = false;
  @Output() handleLogout = new EventEmitter();
  @Output() openClientDrawer = new EventEmitter();
  private _data: DropdownData[] = [];
  private _disableOptions: number[] = [];
  get data() {
    return this._data;
  }

  @Input() set currentClientId(currentClientId: string) {
    this._data = [
      {
        // todo: Also hanldeClick method will be enhanced in future
        text: `Client ID: ${currentClientId}`,
        disabled: true,
        handleClick: () => noop
      },
      {
        text: 'Log out',
        disabled: false,
        handleClick: () => this.handleLogout.emit()
      }
    ];
  }

  @Input() set disableOptions(disableOptions: number[]) {
    this._disableOptions = disableOptions;
  }

  @Input() additionalOptions: DropdownData[] = [];

  ngOnChanges() {
    const footerDropdownData = [...this._data];
    if (this.isEnableSwitchClient) {
      const switchText = 'Select a different client ID...';
      const switchClient = {
        text: switchText,
        disabled: false,
        handleClick: () => this.openClientDrawer.emit()
      };
      const isSwitchClientExists = this._data.some(d => d.text.includes(switchText));
      if (!isSwitchClientExists) {
        footerDropdownData.splice(1, 0, switchClient);
      }
    }
    if (this.additionalOptions.length > 0) {
      const found = this.additionalOptions.some(item => footerDropdownData.includes(item));
      if (!found) {
        footerDropdownData.splice(-1, 0, ...this.additionalOptions);
      }
    }
    this._data = footerDropdownData;
    this._disableOptions.forEach(index => (this._data[index].disabled = true));
  }
}
