<!--
  Breaking these into further components (icon component, item component, etc) breaks some level of the expected layers from the design system.
-->
<ng-container *ngIf="vm$ | async as vm">
  <div>
    <a
      *ngFor="let item of vm.menuItems"
      class="navMenuLink"
      [title]="item.title"
      [attr.aria-label]="item.title"
      (click)="clickIcon(item)"
    >
      <svg
        [ngClass]="{ active: item === vm.activeItem }"
        class="mri-icon__svg navMenuSvg"
        viewBox="0 0 30 55"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use [attr.href]="itemHref(item)"></use>
      </svg>
    </a>
  </div>

  <svg *ngIf="vm.showXLogo" class="version-logo" focusable="false" viewBox="0 0 19 21">
    <path id="Keystone" d="M6.1,2l0.7-0.6c0,0,3-2.4,6,0.6L9.5,6.4L6.1,2z" class="logoKeystone" />
    <path
      id="X_shape"
      d="M7.7,10.3L2,17.8l0,0l-2,2.7c1.1,0,2.5,0.1,4.1-1.3l0.6-0.6l4.8-6.4l0,0l4.9,6.4l0.6,0.6 c1.6,1.4,3,1.3,4.1,1.3l-2-2.7l0,0l-5.5-7.4l0,0l5.2-7.1l0,0l2-2.7c-1.1,0-2.5-0.1-4.1,1.3l-0.6,0.6L9.5,8.2l0,0L5.2,2.5L4.6,1.8 c-1.6-1.4-3-1.3-4.1-1.3l2,2.7l0,0L7.7,10.3"
      class="logoX"
    />
  </svg>
</ng-container>
