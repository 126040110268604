<mri-shared-shell>
  <mri-shared-page-footer
    *ngIf="vm$ | async as vm"
    pageFooter
    [user]="vm.userInfo"
    [currentClientId]="vm.currentClientId"
    [isEnableSwitchClient]="vm.isOperationalUser"
    [additionalOptions]="[enrollmentMenuItem, userPreferenceMenuItem]"
    (handleLogout)="handleLogout()"
    (openClientDrawer)="openClientDrawer()"
  >
  </mri-shared-page-footer>
</mri-shared-shell>
<div kendoDialogContainer></div>
<!-- we are using vm two time here, we will have to think of a better approach -->
<mri-ie-session-timeout-dialog
  *ngIf="vm$ | async as vm"
  [show]="vm.showSessionTimeoutDialog"
  (action)="sessionTimeoutDialogAction($event)"
></mri-ie-session-timeout-dialog>
