import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SupportRoleIds } from '@mri-platform/import-export/clients-state';
import { EnrollmentDialogService, UserPreferenceDialogService } from '@mri-platform/import-export/common-ui';
import { AppRoutes } from '@mri-platform/import-export/core';
import { isNotNullOrUndefined, LogoutPromptService } from '@mri-platform/shared/core';
import { AuthService, User } from '@mri/angular-wfe-proxy-oidc';
import { RxState } from '@rx-angular/state';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

interface ComponentState {
  userInfo: User;
  isOperationalUser: boolean;
  currentClientId: string;
  showSessionTimeoutDialog: boolean;
}

type PublicState = ComponentState;

// interface Projections {}

type ViewModel = PublicState; // & Projections;

const initialPublicState: PublicState = {
  userInfo: { hasValidAccessToken: false, sub: '' },
  isOperationalUser: false,
  currentClientId: '',
  showSessionTimeoutDialog: false
};

const initialState: ComponentState = {
  ...initialPublicState
};

@Component({
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ShellComponent {
  vm$: Observable<ViewModel>;

  enrollmentMenuItem = {
    text: 'Enrollment',
    disabled: false,
    handleClick: () => this.enrollmentDialogService.openEnrollmentDialog()
  };

  userPreferenceMenuItem = {
    text: 'User Preferences',
    disabled: false,
    handleClick: () => this.userPreferenceDialogService.openUserPreferenceDialog()
  };

  constructor(
    private state: RxState<ComponentState>,
    private authService: AuthService,
    private logoutPromptService: LogoutPromptService,
    private router: Router,
    private oauthService: OAuthService,
    private enrollmentDialogService: EnrollmentDialogService,
    private userPreferenceDialogService: UserPreferenceDialogService
  ) {
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...
    this.state.connect('userInfo', this.authService.userLoaded$);
    this.state.connect(
      'showSessionTimeoutDialog',
      this.oauthService.events.pipe(map(e => e.type === 'token_refresh_error'))
    );
    this.state.connect(
      'isOperationalUser',
      this.authService.userLoaded$.pipe(
        filter(userLoaded => !!userLoaded),
        switchMap(_ =>
          this.authService.currentUser$.pipe(map(user => user.roleIds.some((r: number) => SupportRoleIds.includes(r))))
        )
      )
    );
    this.state.connect('currentClientId', this.authService.currentClientId$.pipe(isNotNullOrUndefined()));

    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...

    this.vm$ = this.state.select();

    // side effects if any...
    // none
  }

  async handleLogout() {
    const canProceed = await this.logoutPromptService.showLogoutPrompt$().toPromise();
    if (canProceed) {
      this.authService.logOut();
    }
  }

  openClientDrawer() {
    this.router.navigate([{ outlets: { drawer: [AppRoutes.SwitchClient.path] } }]);
  }

  sessionTimeoutDialogAction(resumeSession: boolean) {
    if (resumeSession) {
      this.oauthService.setupAutomaticSilentRefresh();
    } else {
      this.authService.logOut();
    }
  }
}
