import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHandlingModule, ErrorHandlingModuleConfig } from '@mri-platform/angular-error-handling';
import { AngularErrorLoggingModule, ErrorLoggingModuleConfig } from '@mri-platform/angular-error-logging';
import {
  AngularResourceAuthzConfig,
  AngularResourceAuthzModule,
  ResourceAuthzGuard
} from '@mri-platform/angular-resource-authz';
import { KendoToastErrorAppenderService, KendoUiToastAppenderModule } from '@mri-platform/kendo-ui-toast-appender';
import { ErrorNotifierService, ResourceAuthzGuardInjectionToken } from '@mri-platform/shared/core';
import { WfeProxyOidcModule } from '@mri/angular-wfe-proxy-oidc';
import { AgWidgetConfigProvider } from './app-config/ag-widget-config.provider';
import { ApiConfigProvider } from './app-config/api.config.provider';
import { AppGatewayConfigProvider } from './app-config/app-gateway-config.provider';
import { FeatureFlagsConfigProvider } from './app-config/feature-flags-config.provider';
import { SettingsProvider } from './app-config/settings.provider';
import { AuthConfigProvider } from './auth/auth-config.provider';
import { DefaultResourceAuthzSessionService } from './auth/default-resource-authz-session.service';
import { OAuthModuleConfigProvider } from './auth/oauth-module-config.provider';
import { ClientVsInitializerProvider } from './version-info/client-vs-initializer.provider';

const defaultResourceAuthzConfig: AngularResourceAuthzConfig = {
  sessionServiceType: DefaultResourceAuthzSessionService
};

export interface AppCoreIntegrationConfig {
  errorHandling?: ErrorHandlingModuleConfig;
  errorLogging?: ErrorLoggingModuleConfig;
  resourceAuthz?: AngularResourceAuthzConfig;
}

@NgModule({
  imports: [
    WfeProxyOidcModule,
    ErrorHandlingModule,
    AngularErrorLoggingModule,
    KendoUiToastAppenderModule,
    AngularResourceAuthzModule
  ]
})
export class AppCoreIntegrationModule {
  static forRoot(config: AppCoreIntegrationConfig = {}): ModuleWithProviders<AppCoreIntegrationModule> {
    return {
      ngModule: AppCoreIntegrationModule,
      providers: [
        WfeProxyOidcModule.forRoot().providers ?? [],
        AngularErrorLoggingModule.forRoot(config.errorLogging).providers ?? [],
        ErrorHandlingModule.forRoot(config.errorHandling).providers ?? [],
        AngularResourceAuthzModule.forRoot(config.resourceAuthz ?? defaultResourceAuthzConfig).providers ?? [],
        [
          ApiConfigProvider,
          AppGatewayConfigProvider,
          FeatureFlagsConfigProvider,
          SettingsProvider,
          AuthConfigProvider,
          AgWidgetConfigProvider,
          { provide: ResourceAuthzGuardInjectionToken, useExisting: ResourceAuthzGuard },
          OAuthModuleConfigProvider,
          ClientVsInitializerProvider,
          { provide: ErrorNotifierService, useExisting: KendoToastErrorAppenderService }
        ]
      ]
    };
  }
}
