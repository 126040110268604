import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { FileType } from '@mri-platform/import-export/common-state';
import { ApiConfig } from '@mri-platform/shared/core';
import { DefaultEntityService, EntityIdType, isEntityNew } from '@mri-platform/shared/entity';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { JobLog } from '../models';
import { platformJobLogsSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class JobLogEntityService extends DefaultEntityService<JobLog> {
  constructor(
    injector: Injector,
    private http: HttpClient,
    private apiConfig: ApiConfig
  ) {
    super(JobLog.entityName, injector);
  }

  entitiesByPlatformId$(id: EntityIdType) {
    return this.store.select(platformJobLogsSelectors.selectEntitiesForPlatformId(id));
  }

  save(entity: JobLog) {
    if (isEntityNew(entity, this.selectId)) {
      return this.add(JobLog.omitId(entity));
    } else {
      return this.update(entity);
    }
  }

  newEntitiesByFilter$(options: { [key: string]: unknown }): Observable<JobLog[]> {
    const url = `${this.apiConfig.url}/joblogs/filtered`;
    return this.http.post<JobLog[]>(url, options).pipe(tap(jobLogs => this.upsertManyInCache(jobLogs)));
  }

  entitiesByFilter$(options: { [key: string]: unknown }): Observable<JobLog[]> {
    this.setLoading(true);
    const url = `${this.apiConfig.url}/joblogs/filtered`;
    return this.http.post<JobLog[]>(url, options).pipe(
      tap(jobLogs => this.addAllToCache(jobLogs)),
      finalize(() => this.setLoading(false))
    );
  }

  async downloadJobLog(config: { [key: string]: unknown }) {
    config.downloadFile = true;
    const url = `${this.apiConfig.url}/joblogs/filtered`;
    const blob = await this.http.post(url, config, { responseType: 'blob' }).toPromise();
    if (blob) {
      saveAs(blob, `importexportauditlog_${format(new Date(), 'ddMMyyyyHHmmss')}.${FileType.Csv}`);
    }
    // TODO: display error message if blob is null
  }
}
